function getDefaultState() {
  return {
    trgDate:'',
    data: [],
    selectData : [],
  }
}

const state = getDefaultState()

const getters = {
  trgDate (state){
    return state.trgDate
  },
  trgData(state) {
    return state.selectData
  }
};

const mutations = {
  // 初期化
  clearAuthData(state) {
    Object.assign(state, getDefaultState())
  },
  selectData(state, val){
    console.log("selectData")
    console.log(val)
    state.selectData = val;
  },
  selectDate(state, date){
    state.trgDate = date;
  },
};

const actions = {
  selectData({commit},val){
    commit('selectData',val);
  },
  selectDate({commit},val){
    console.log("selectDate : " + val)
    commit('selectDate',val);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};