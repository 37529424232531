<template>
  <v-app>
    <Header></Header>
    <v-layout row justify-center align-center>
      <v-flex xs7>
        <v-layout justify-start>
          <!-- 戻るボタン -->
            <v-btn color='primary' class="addBtn" @click="clickBackBtn">
              <v-icon color="white">mdi-keyboard-return</v-icon>
                {{ stringData.back }}
            </v-btn>
        </v-layout>
        <v-card>
          <v-card-title>
            <h1 class="title">
              {{ stringData.title }}
            </h1>
          </v-card-title>
          <v-card-text>
            <!-- 登録済みデータ一覧 -->
            <v-layout column v-for="(value, index) in items" :key="index">
              <div class="grayBorder">
                <v-layout row>
                  <v-flex xs10>
                    <v-layout column>
                      <!-- 開始時刻 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.startTime }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont" v-if="value.startTime == backUpItems[index].startTime">{{ value.startTime }}</div>
                            <div class="infoMessageFont editData" v-else>{{ value.startTime }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <!-- 終了時刻 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.endTime }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont" v-if="value.endTime == backUpItems[index].endTime">{{ value.endTime }}</div>
                            <div class="infoMessageFont editData" v-else>{{ value.endTime }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <!-- 作業場所 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.workPlace }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont">{{ value.workPlace }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <!-- 概要 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.overview }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont">{{ value.overview }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                    </v-layout>
                  </v-flex>

                  <v-flex xs1>
                    <v-layout column>
                      <v-col cols="12" sm="11" md="8">
                        <!-- 編集ボタン -->
                        <v-btn
                          color="primary"
                          elevation="2"
                          fab
                          dark
                          small
                          @click="showEditDlg(value)"
                          v-if="SamePerson()"
                        >
                        <!-- <v-btn
                          color="primary"
                          elevation="2"
                          fab
                          dark
                          small
                          @click="dataEdit(value)"
                          v-if="SamePerson()"
                        > -->
                          <v-icon dark>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                        <v-btn
                          color="primary"
                          elevation="2"
                          fab
                          dark
                          small
                          @click="applyEdit(value)"
                        >
                        適用
                        </v-btn>
                      </v-col>
                    </v-layout>
                    <v-dialog v-model="value.isEditDialog" max-width="400" @close="closeEditDlg(value)">
                      <Input :value="value">
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </div>
            </v-layout>
            <!-- 登録用のUI -->
            <v-layout v-if="SamePerson()" column>
              <!-- ヘッダ -->
              <v-layout align-center row>              
                <!-- 開始時刻 -->
                <div id='startTimeTitle' class='headerTitleStart stratTime'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleStartTime }}
                  </span>
                </div>
                <!-- 終了時刻 -->
                <div id='endTimeTitle' class='headerTitle endTime'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleEndTime }}
                  </span>
                </div>
                <!-- 作業場所 -->
                <div id='workPlaceTitle' class='headerTitle workPlace'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleWorkPlace }}
                  </span>
                </div>
                <!-- 概要 -->
                <div id='overviewTitle' class='headerTitleEnd overview'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleOverview }}
                  </span>
                </div>
              </v-layout>
              <!-- データ部 -->
              <v-layout align-center row v-for="(value, index) in listData" :key="index">
                <!-- 開始時刻 -->
                <div class='dataItem stratTime dataHeight' @click="showDlg(value)">
                  <span class='dataFont' style="white-space: pre-line;">
                    {{ value.startTime }}
                  </span>
                </div>
                <!-- 終了時刻 -->
                <div class='dataItem endTime dataHeight' @click="showDlg(value)">
                  <span class='dataFont' style="white-space: pre-line;">
                    {{ value.endTime }}
                  </span>
                </div>
                <!-- 作業場所 -->
                <div class='dataItem workPlace dataHeight' @click="showDlg(value)">
                    <span class='dataFont' style="white-space: pre-line;">
                      {{ value.workPlace }}
                    </span>
                </div>
                <!-- 概要 -->
                <v-text class='dataItemEnd overview dataFont dataHeight' @click="showDlg(value)" style="white-space: pre-line;">
                  <!-- <span class="dataFont dataHeight"> -->
                    {{ value.overview }}
                  <!-- </span> -->
                </v-text>
                <!-- 追加ボタン -->
                <v-btn 
                  color="primary" 
                  class="addBtn" 
                  @click="dataAdd(index)" 
                  v-if="listData.length - 1 == index" 
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ stringData.add }}
                </v-btn>
                <!-- 削除ボタン -->
                <v-btn color="primary" class="delBtn" @click="dataDelete(index)" v-if="listData.length > 1">
                  <v-icon>mdi-delete</v-icon>
                  {{ stringData.delete }}
                </v-btn>
                <v-dialog v-model="value.isShowDlg" max-width="400" @close="dlgClose(value)">
                  <Input :value="value">
                </v-dialog>
              </v-layout>
              <!-- 登録ボタン -->
              <v-btn color="primary"  class="applyBtn" @click="dataApply()">
                <v-icon>mdi-plus</v-icon>
                {{ stringData.apply }}
              </v-btn>
            </v-layout>
          </v-card-text>
          
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Header from '../components/header.vue'
import Input from '../components/input.vue'
// import Push from 'push.js'
export default {
  name: 'edit',
  // コンポーネント
  components: {
    Header,
    Input,
  },
  /* 変数各種(関数形式でreturn値が変数として使用できる) */
  data () {
    return {
      /* 文字列データ */
      stringData: {
        title: '当日データ',
        back: '戻る',
        startTime: '開始時刻',
        endTime: '終了時刻',
        workPlace: '作業場所',
        overview: '概要',
        registrationTime: '登録時刻',
        add: '追加',
        apply: '登録',
        headerTitleDate: '日付',
        headerTitleState: '出勤状況',
        headerTitleStartTime: '開始時刻',
        headerTitleEndTime: '終了時刻',
        headerTitleWorkPlace: '作業場所',
        headerTitleOverview: '概要',
      },

      /* 変数 */
      userId: this.$route.params.id,
      /* 登録済みデータ(編集用) */
      items: [],
      /* 登録済みデータ(差分確認用) */
      backUpItems: [],
      // 登録用データ
      listData: [{startTime: "", endTime: "", workPlace: "", overview: "", isShowDlg:false}],

      todayDate: this.formatDate(new Date()),
    }
  },
  computed: {
    ...mapGetters('DailyReport',[
      "trgDate"
    ]),
    ...mapGetters('User',[
      "selectUserId"
    ]),
  },
  mounted : function() {
    // console.log(this.getDailyReport);
    this.getDailyData()
  },
  methods : {
    ...mapActions('DailyReport',[
      'selectData',
    ]),
    // 新規追加ボタンクリック
    async dataAdd() {            
      this.listData.push({startTime: null, endTime: null, workPlace: "", overview: "", isShowDlg: false, isEditDialog:false})
    },
    // 登録ボタンクリック
    async dataApply() {
      console.log('登録')
      console.log(this.listData)
      this.changeState()
      // 登録・編集用のパラメータ生成
      let params = {};
      params.tokenString = localStorage.getItem("Token");
      // 開始日
      var startDate = this.todayDate;
      // 終了日
      var endDate = this.todayDate;

      let datalist = []
        this.listData.forEach(element => {
          if (element.startTime > element.endTime) {
            endDate.setDate(endDate.getDate() + 1)
          }
          console.log('startDate :' + startDate)
          datalist.push(
            {
              startTime : startDate + " " + element.startTime + ":00",
              endTime: endDate + " " + element.endTime + ":00",
              workPlace: element.workPlace,
              overview: element.overview
          })
        });
        params.insertDataList = datalist
        const url = 'https://testerp2.ecosysnetwork.co.jp:444/InsertTimeCard';
        await axios.post(
          url, 
          params,
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then(res => {
          alert(res.data.msg)
          if (res.data.msg == "登録成功") {
            // 再取得
            this.getDailyData()
            // 登録データリスト初期化
            this.listData = [{startTime: null, endTime: null, workPlace: "", overview: "",}]
          }
        }).catch(err => {
        console.log(err)
        }) 
    },
    // 出勤区分を出勤で登録
    async changeState() {
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/InsertAttendance';
      let params = {};
      params.tokenString = localStorage.getItem("Token")
      params.date = this.formatDate(new Date());
      params.attendanceDivId = "1";
      console.log(params)
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data.msg)
        // alert(res.data.msg)
        this.getAttendance()
      }).catch(err => {
       console.log(err)
      })
    },
    // 戻るボタンクリック
    async clickBackBtn() {
      console.log('clickBackBtn:' )
      // Push.create('更新情報');
      // 一つ前の画面に遷移させる
      this.$router.back()
    },
    /* データ削除 */
    async dataDelete(index) {
      this.listData.splice(index, 1)
    },
    /* データ編集 */
    async dataEdit(value) {
      console.log('dataEdit')
      console.log(value)
      this["selectData"](value)
      this.$router.push({
        name : 'TimeCardAdd',
        params: { mode: 'edit' }
      })
    },
    /* 日報データ取得 */
    async getDailyData() {
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/TimeCard'
      let params = {};
      // 登録済みデータ格納用リスト初期化
      this.items = []
      // トークン
      params.tokenString = localStorage.getItem("Token");
      // ユーザーID
      params.userId = String(this["selectUserId"])
      // 対象日
      params.trgDate = this.formatDate(new Date())
      console.log("today " + params.trgDate)
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data)
        res.data.forEach(element => {
          console.log(element.EndDate)
          console.log(element.EndDate.substr(11, 5))
          this.items.push({
            id: element.ReportId, 
            startTime: element.StartDate.substr(11, 5), 
            endTime: element.EndDate.substr(11, 5), 
            registrationTime: element.RegistrationTime.substr(0, 10) + " " + element.RegistrationTime.substr(11, 5), 
            workPlace: element.WorkPlace, 
            overview: element.Overview,
            isEditDialog:false
          })  
        });
        this.backUpItems = JSON.parse(JSON.stringify(this.items))
      }).catch(err => {
       console.log(err)
      }) 
    },
    /* ダイアログ表示 */
    showDlg(value) {
      value.isShowDlg = true
    },
    /*ダイアログ閉じたとき */    
    dlgClose(value) {
      value.isShowDlg = false
    },
    /* ダイアログ表示 */
    showEditDlg(value) {
      value.isEditDialog = true
    },
    /*ダイアログ閉じたとき */    
    closeEditDlg(value) {
      value.isEditDialog = false
    },
    // 編集データ適用(1データ)
    async applyEdit(value) {
      console.log(value)
      // プログレス表示を行う
      this.cmpFlag = true
      // 編集用のパラメータ生成
      let param = {};
      var startDate = this.todayDate;
      console.log(startDate)
      var endDate = this.todayDate;
      param.tokenString = localStorage.getItem("Token");
      if (value.startTime > value.endTime) {
        endDate.setDate(endDate.getDate() + 1)
      }
      console.log(endDate)
      param.startTime = startDate + " " + value.startTime + ":00";
      console.log(param.startTime)
      param.endTime = endDate + " " + value.endTime + ":00";
      console.log(param.endTime)
      param.workPlace = value.workPlace;
      param.overview = value.overview;
      console.log("編集")
      param.dailyReportId = String(value.id)
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/UpdateTimeCard';
      await axios.post(
        url, 
        param,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data.msg)
        alert(res.data.msg)
        if (res.data.msg == "更新成功") {
          console.log("更新成功")
          this.getDailyData();
        }
      }).catch(err => {
        console.log(err)
      }) 
    }
  },
}
</script>

<style scoped>
.dataHeight {
  min-height: 50px;
  justify-content: center;
  align-items: center;
}
.applyBtn {
  margin-top: 2%;
  width: 10%;
}
.addBtn {
  margin-bottom: 2%;
  width: 10%;
}
.delBtn {
  margin-bottom: 2%;
  width: 10%;
}
.u-pre-wrap {
  white-space: pre-wrap;
}
.editData {
  color: #fc0000;
}
</style>