<template>
  <v-app>
    <Header></Header>
    <v-layout row justify-center align-center>
      <v-flex xs7>
        <v-layout justify-start>
          <!-- 戻るボタン -->
            <v-btn color='primary' class="addBtn" @click="clickBackBtn">
              <v-icon color="white">mdi-keyboard-return</v-icon>
                {{ stringData.back }}
            </v-btn>
        </v-layout>

        <v-card>
          <v-card-title>
            <h1 class="title" v-if="this.mode == 'add'">
              {{ stringData.title }}
            </h1>
            <h1 v-else>
              {{ stringData.editTitle }}
            </h1>
          </v-card-title>
          <v-card-text>
            <v-layout justify-start>
                <div class="infoMessageFont">{{ stringData.info }}</div>
            </v-layout>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div class="grayBorder">
                <div class="grayBorder" v-for="(value, index) in listData" :key="index">
                  <Input :value="value"/>
                  <!-- 削除ボタン -->
                  <v-card-actions v-if="mode != 'edit'">
                    <v-layout row justify-center>
                      <div v-if="!cmpFlag">
                        <v-btn
                          color="primary"
                          @click="listDataDel(index)"
                        >
                          削除
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                    </v-layout>
                  </v-card-actions>
                </div>
                <v-col>
                  <!-- 追加ボタン -->
                  <v-card-actions v-if="mode != 'edit'">
                    <v-layout row justify-center>
                      <div v-if="!cmpFlag">
                        <v-btn
                          color="primary"
                          @click="listDataAdd()"
                        >
                          {{ stringData.add }}
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                    </v-layout>
                  </v-card-actions>
                </v-col>
                <!-- 確定ボタン -->
                <v-col>
                  <v-card-actions>
                    <v-layout row justify-center>
                      <div v-if="!cmpFlag">
                        <v-btn
                          color="primary"
                          @click="dataAdd()"
                        >
                          {{ stringData.confirm }}
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </div>
                    </v-layout>
                  </v-card-actions>
                </v-col>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Header from '../components/header.vue'
import Input from '../components/input.vue'
export default {
  name: 'edit',
  props: ['mode'],
  // コンポーネント
  components: {
    Header,
    Input
  },
  /* 変数各種(関数形式でreturn値が変数として使用できる) */
  data () {
    return {
      /* 文字列データ */
      stringData: {
        title: '新規追加',
        editTitle: '編集',
        back: '戻る',
        headerTitleDate: '日付',
        headerTitleState: '出勤状況',
        startTime: '開始時刻',
        endTime: '終了時刻',
        workPlace: '作業場所',
        overview: '概要',
        confirm: '確定',
        add: '追加',
        delete:'削除',
        infoState: '出勤状況を選択し、確定ボタンを押下してください。',
        attentionInfo: '業務内容は「出勤」選択時のみ可能です。',
        info: '行った業務内容を入力し、確定ボタンを押下してください。',
        required: 'は必須です',
        incorrectDataFormat: '入力データの形式が不正です',
        insufficientNumberOfCharacters: '字以上入力してください',
        overViewMaxLength: '200字以内で入力してください'
      },

      /* 勤怠状況 */
      statusTypes: [
        '出勤', '有給', '代休', '欠勤'
      ],

      /* 日付バリデーション */
      dateRules: [
        v => !!v || this.stringData.headerTitleDate + this.stringData.required,
        v => /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || this.stringData.incorrectDataFormat,
      ],
      /* 開始時刻バリデーション */
      startTimeRules: [
        v => !!v || this.stringData.startTime + this.stringData.required,
        v => /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/.test(v) || this.stringData.incorrectDataFormat,
      ],
      /* 終了時刻バリデーション */
      endTimeRules: [
        v => !!v || this.stringData.endTime + this.stringData.required,
        v => /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/.test(v) || this.stringData.incorrectDataFormat,
      ],
      /* 作業場所バリデーション */
      workPlaceRules: [
        v => !!v || this.stringData.workPlace + this.stringData.required,
      ],
      /* 概要バリデーション */
      overviewRules: [
        // v => {
        //   console.log("概要バリデーション");
        //   console.log(this["summaryNumberOfDharacters"] > 0);
        //   console.log(!!v);
        //   ((this["summaryNumberOfDharacters"] > 0 || !!v)) || this.stringData.overview + this.stringData.required
        //   },
        v => String(v).length >= this["summaryNumberOfDharacters"] || this["summaryNumberOfDharacters"] + this.stringData.insufficientNumberOfCharacters,
        v => String(v).length < 200 || this.stringData.overViewMaxLength
      ],

      /* 変数 */
      stateValid: true,
      stateCmpFlag: false,
      valid: true,
      cmpFlag: false,
      stateDate: new Date().toISOString().substr(0, 10),
      status: null,
      startTime: null,
      endTime: null,
      workPlace: null,
      overview: null,
      stateDateMenu: false,
      dateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      stateParam: false,
      userId: this.$route.params.id,
      listData: [{startTime: null, endTime: null, workPlace: null, overview: null,}],
    }
  },

  computed: {
    ...mapGetters('DailyReport',[
      "trgData",
      "trgDate"
    ]),
    ...mapGetters('User',[
      "summaryNumberOfDharacters",
    ]),
  },
  mounted : function() {
    console.log(this["trgDate"])
    if (this["trgDate"] != '') {
      this.stateDate = new Date(this["trgDate"]).toISOString().substr(0, 10)
    }
    if (this.mode == "edit") {
      console.log("mode : edit")
      this.stateParam = true
      console.log("listData")
      console.log(this.listData)
      console.log("trgData")
      console.log(this["trgData"])
      
      this.listData[0].startTime = this["trgData"].startTime
      this.listData[0].endTime = this["trgData"].endTime
      this.listData[0].workPlace = this["trgData"].workPlace
      this.listData[0].overview = this["trgData"].overview
      
      console.log("listData")
      console.log(this.listData)
    }
    
  },
  methods : {
    // 戻るボタンクリック
    async clickBackBtn() {
      console.log('clickBackBtn:' )
      // 一つ前の画面に遷移させる
      this.$router.back()
    },
    // 日報データ追加
    listDataAdd() {
      this.listData.push({startTime: null, endTime: null, workPlace: null, overview: null,})
    },
    // 日報データ削除
    listDataDel(index) {
      this.listData.splice(index, 1)
    },
    /* データ確定 */
    async stateAdd() {
      // プログレス表示を行う
      this.stateCmpFlag = true
      console.log(this.stateCmpFlag)
      this.$refs.stateForm.validate()
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/InsertAttendance';
      let params = {};
      params.tokenString = localStorage.getItem("Token")
      params.date = this.stateDate;
      var i;
      if (this.status == this.statusTypes[0]) {
        i = 1;
      } else if (this.status == this.statusTypes[1]) {
        i = 2;
      } else if (this.status == this.statusTypes[2]) {
        i = 3;
      } else if (this.status == this.statusTypes[3]) {
        i = 4;
      }
      params.attendanceDivId = String(i);
      console.log(params)
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data.msg)
        alert(res.data.msg)
      }).catch(err => {
       console.log(err)
      })
      this.stateCmpFlag = false  
    },

    /* データ確定 */
    async dataAdd() {
      console.log(this.stateCmpFlag)
      if (this.$refs.form.validate() == false) {
        return
      }
      // プログレス表示を行う
      this.cmpFlag = true
      // 登録・編集用のパラメータ生成
      let params = {};
      var startDate = new Date(this.stateDate);
      var endDate = new Date(this.stateDate);
      params.tokenString = localStorage.getItem("Token");

      if (this.mode == "edit") {
        console.log(this.listData[0].startTime)
        console.log(this.listData[0].endTime)
        if (this.listData[0].startTime > this.listData[0].endTime) {
          endDate.setDate(endDate.getDate() + 1)
        }
        params.startTime = startDate.toISOString().substr(0, 10) + " " + this.listData[0].startTime + ":00";
        params.endTime = endDate.toISOString().substr(0, 10) + " " + this.listData[0].endTime + ":00";
        params.workPlace = this.listData[0].workPlace;
        params.overview = this.listData[0].overview;
        console.log("編集")
        console.log(String(this["trgData"]))
        params.dailyReportId = String(this["trgData"].id)
        const url = 'https://testerp2.ecosysnetwork.co.jp:444/UpdateTimeCard';
        await axios.post(
          url, 
          params,
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then(res => {
          console.log(res.data.msg)
          alert(res.data.msg)
          if (res.data.msg == "更新成功") {
            console.log("遷移")
            // 画面遷移
            this.$router.push({
              name: 'TimeCard',
            })
          }
        }).catch(err => {
        console.log(err)
        })  
      }
      else {
        let datalist = []
        this.listData.forEach(element => {
          if (element.startTime > element.endTime) {
            endDate.setDate(endDate.getDate() + 1)
          }
          datalist.push(
            {
              startTime : startDate.toISOString().substr(0, 10) + " " + element.startTime + ":00",
              endTime: endDate.toISOString().substr(0, 10) + " " + element.endTime + ":00",
              workPlace: element.workPlace,
              overview: element.overview
          })
        });
        params.insertDataList = datalist
        console.log("追加")
        console.log(startDate.toISOString().substr(0, 10) + " " + endDate.toISOString().substr(0, 10))
        const url = 'https://testerp2.ecosysnetwork.co.jp:444/InsertTimeCard';
        await axios.post(
          url, 
          params,
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then(res => {
          alert(res.data.msg)
          if (res.data.msg == "登録成功") {
            console.log("遷移")
            // 画面遷移
            this.$router.push({
              name: 'TimeCard',
            })
          }
        }).catch(err => {
        console.log(err)
        })  
      }
      this.cmpFlag = false
    },
  }
}
</script>

<style>
/* 枠線スタイル */
.grayBorder {
  border-radius: 0.5em;
  border: thin solid #c0c0c0;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-bottom: 3%;
}

/* 説明フォントスタイル */
.infoMessageFont {
  margin-top: 2%;
  margin-bottom: 2%;
  font-family:'メイリオ', 'Meiryo', sans-serif;
  color: #696969;
}

/* 勤怠状況スタイル */
.status {
  margin-bottom: 4%;
}
</style>