const state = {
    data: [],
  };
  
  const getters = {
    data (state){
      console.log("holiday-getters")
      console.log(state.data)
      return state.data
    }
  };
  
  const mutations = {
    add(state, dataList){
      console.log(dataList)
      state.data = dataList;
    },
  };
  
  const actions = {
    add({commit},val){
      console.log("add")
      commit('add',val);
    },
  };
  export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
  };