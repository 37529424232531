<template>
  <div class="input">
    <!-- 開始時刻入力項目 -->
    <v-col
      cols="11"
      sm="5"
    >
      <v-menu
        ref="startTimeMenu"
        :close-on-content-click="false"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            clearable
            v-model="value.startTime"
            :label="stringData.startTime"
            v-bind="attrs"
            v-on="on"
            :rules="startTimeRules"
          />
        </template>
        <v-time-picker
          v-model="value.startTime"
          full-width
          @click:minute="$refs.menu.save(startTime)"
        ></v-time-picker>
      </v-menu>
    </v-col>

    <!-- 終了時刻入力項目 -->
    <v-col
      cols="11"
      sm="5"
    >
      <v-menu
        ref="endTimeMenu"
        :close-on-content-click="false"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            clearable
            v-model="value.endTime"
            :label="stringData.endTime"
            v-bind="attrs"
            v-on="on"
            :rules="endTimeRules"
          />
        </template>
        <v-time-picker
          v-model="value.endTime"
          full-width
          @click:minute="$refs.menu.save(endTime)"
        ></v-time-picker>
      </v-menu>
    </v-col>
    <!-- 作業場所入力項目 -->
    <v-col
      cols="12"
      sm="11"
      md="8"
    >
      <v-text-field
        outlined
        clearable
        v-model="value.workPlace"
        :label="stringData.workPlace"
        :rules="workPlaceRules"
      />
    </v-col>

    <!-- 概要入力項目 -->
    <v-col
      cols="12"
      sm="11"
      md="8"
    >
      <v-textarea
        outlined
        clearable
        v-model="value.overview"
        :label="stringData.overview"
        :rules="overviewRules"
        oncopy="return false"
        onpaste="return false"
      />
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['value'],

    data() {
      return {
      /* 文字列データ */
      stringData: {
        title: '新規追加',
        editTitle: '編集',
        back: '戻る',
        headerTitleDate: '日付',
        headerTitleState: '出勤状況',
        startTime: '開始時刻',
        endTime: '終了時刻',
        workPlace: '作業場所',
        overview: '概要',
        confirm: '確定',
        add: '追加',
        delete:'削除',
        infoState: '出勤状況を選択し、確定ボタンを押下してください。',
        attentionInfo: '業務内容は「出勤」選択時のみ可能です。',
        info: '行った業務内容を入力し、確定ボタンを押下してください。',
        required: 'は必須です',
        incorrectDataFormat: '入力データの形式が不正です',
        insufficientNumberOfCharacters: '字以上入力してください',
        overViewMaxLength: '200字以内で入力してください'
      },
      /* 日付バリデーション */
      dateRules: [
        v => !!v || this.stringData.headerTitleDate + this.stringData.required,
        v => /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || this.stringData.incorrectDataFormat,
      ],
      /* 開始時刻バリデーション */
      startTimeRules: [
        v => !!v || this.stringData.startTime + this.stringData.required,
        v => /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/.test(v) || this.stringData.incorrectDataFormat,
      ],
      /* 終了時刻バリデーション */
      endTimeRules: [
        v => !!v || this.stringData.endTime + this.stringData.required,
        v => /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/.test(v) || this.stringData.incorrectDataFormat,
      ],
      /* 作業場所バリデーション */
      workPlaceRules: [
        v => !!v || this.stringData.workPlace + this.stringData.required,
      ],
      /* 概要バリデーション */
      overviewRules: [
        v => { 
          var s = String(v).replace("\r\n", "")
          return s.length >= this["summaryNumberOfDharacters"] || this["summaryNumberOfDharacters"] + this.stringData.insufficientNumberOfCharacters
        },
        v => {
          var s = String(v).replace("\r\n", "")
          console.log("s.lenght" + s.length)
          return s.length < 200 || this.stringData.overViewMaxLength
        }
      ],
    }
  },

  computed: {
    ...mapGetters('User',[
      "summaryNumberOfDharacters",
    ]),
  }
}

</script>

<style>
.input{
  background-color:white;
}
</style>