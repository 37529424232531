// import createPersistedState from 'vuex-persistedstate'

function initialState () {
  return {
    /* state */ 
    // タイムカード開始日
    startDate: 21,
    // タイムカード終了日
    closingDate: 20,
    // 概要入力最低文字数
    summaryNumberOfDharacters: 0,
    // ログインユーザーID
    loginUserId: -1,
    // 選択ユーザーID
    selectUserId: -1,
    // ログインユーザー名
    userName: "",
    // 会社ID
    companyId: -1,
  }
}

// const state = {
//   // タイムカード開始日
//   startDate: 21,
//   // タイムカード終了日
//   closingDate: 20,
//   // 概要入力最低文字数
//   summaryNumberOfDharacters: 0,
//   // ログインユーザーID
//   loginUserId: -1,
//   // 選択ユーザーID
//   selectUserId: -1,
//   // ログインユーザー名
//   userName: "",
//   // 会社ID
//   companyId: -1,
// };

const state = initialState()

const getters = {
  startDate (state) {
    return state.startDate
  },
  closingDate (state) {
    return state.closingDate
  },
  summaryNumberOfDharacters(state) {
    return state.summaryNumberOfDharacters
  },
  loginUserId(state){
    console.log(state.loginUserId)
    return state.loginUserId
  },
  selectUserId(state){
    console.log(state.selectUserId)
    return state.selectUserId
  },
  userName(state){
    console.log(state.userName)
    return state.userName
  },
  companyId(state){
    console.log(state.companyId)
    return state.companyId
  },
};

const mutations = {
  ResetUser(state) {
    Object.assign(state, initialState())
  },
  setStartDate(state, date){
    state.startDate = date;
  },
  setClosingDate(state, date){
    state.closingDate = date;
  },
  setSummaryNumberOfCharacters(state, val){
    state.summaryNumberOfDharacters = val;
  },
  setLoginUserId(state, val){
    state.loginUserId = val;
    console.log(state.loginUserId)
  },
  setSelectUserId(state, val){
    state.selectUserId = val;
    console.log(state.selectUserId)
  },
  setUserName(state, val){
    state.userName = val;
    console.log(state.userName)
  },
  setCompanyId(state, val){
    state.companyId = val;
    console.log(state.companyId)
  },
};

const actions = {
  setStartDate({commit},val){
    commit('setStartDate',val);
  },
  setClosingDate({commit},val){
    commit('setClosingDate',val);
  },
  setSummaryNumberOfCharacters({commit},val){
    commit('setSummaryNumberOfCharacters',val);
  },
  setLoginUserId({commit},val){
    commit('setLoginUserId',val);
  },
  setSelectUserId({commit},val){
    commit('setSelectUserId',val);
  },
  setUserName({commit},val){
    commit('setUserName',val);
  },
  setCompanyId({commit},val){
    commit('setCompanyId',val);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};