<template>
  <v-app>
    <Header></Header>
    <!-- タイトル -->
    <v-layout justify-start class='timeCardTitle'>
      <h1>
        {{ stringData.title }}
      </h1>
    </v-layout>
    <!-- タイムカード一覧 -->
    <v-layout justify-center align-start>
      <v-flex xs11>
        <!-- elevationプロパティはカードの影の強さ -->
        <v-card
          elevation='3'
          class='timeCard'
        >
          <!-- リスト -->
          <v-layout justify-center>
            <v-flex xs11>
              <v-layout justify-start class="mb-5">
                <v-flex xs10>
                  <v-row dense>
                    <v-col cols="1">
                      <v-select
                        v-model="selectUserId"
                        :items="userList"
                        item-text="name"
                        item-value="id"
                        @change="changeUser()"
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="selectYear"
                        :items="yearList"
                        outlined
                        label="年"
                        dense
                        hide-details
                        @change="changeMonth()"
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="selectMonth"
                        :items="monthList"
                        outlined
                        label="月"
                        dense
                        hide-details
                        @change="changeMonth()"
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        color="primary"
                        @click="todayData"
                        v-if="checkState() && SamePerson()"
                      >
                        当日データ登録
                      </v-btn>                      
                    </v-col>
                  </v-row>
                </v-flex>
              </v-layout>
              <!-- ヘッダ -->
              <v-layout row >
                <!-- 日付 -->
                <div id='startTimeTitle' class='headerTitleStart date'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleDate }}
                  </span>
                </div>
                <!-- 出勤状況 -->
                <div id='startTimeTitle' class='headerTitle state'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleState }}
                  </span>
                </div>
                <!-- 開始時刻 -->
                <div id='startTimeTitle' class='headerTitle stratTime'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleStartTime }}
                  </span>
                </div>
                <!-- 終了時刻 -->
                <div id='endTimeTitle' class='headerTitle endTime'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleEndTime }}
                  </span>
                </div>
                <!-- 勤務時間 -->
                <div id='workingHoursTitle' class='headerTitle workingHours'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleWorkingHours }}
                  </span>
                </div>
                <!-- 残業時間 -->
                <div id='overtimeHoursTitle' class='headerTitle overtimeHours'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleOvertimeHours }}
                  </span>
                </div>
                <!-- 深夜残業時間 -->
                <div id='midnightOvertimeHoursTitle' class='headerTitleEnd overtimeHours'>
                  <span class='headerFont' >
                    {{ stringData.headerTitleMidnightOvertimeHours }}
                  </span>
                </div>
              </v-layout>

              <!-- データ部 -->
              <v-layout row v-for="(value, index) in listData" :key="index">
                <!-- 日時 -->
                <div class='dataItem date'>
                  <span :class='dataFont(value.IsHoliday)' >
                    {{ value.dateString }}
                  </span>
                </div>
                <!-- 出勤状況 -->
                <div class='dataItem state'>
                  <v-select
                    v-model="value.state"
                    :items="statusTypes"
                    @change="changeState(value)"
                    v-if="SamePerson()"
                  ></v-select>
                  <span v-else class='dataFont' >
                    {{ value.state }}
                  </span>
                </div>
                <!-- 開始時刻 -->
                <div class='dataItem stratTime'>
                  <span class='dataFont' >
                    {{ value.startTime }}
                  </span>
                </div>
                <!-- 終了時刻 -->
                <div class='dataItem endTime'>
                  <span class='dataFont' >
                    {{ value.endTime }}
                  </span>
                </div>
                <!-- 勤務時間 -->
                <div class='dataItem workingHours'>
                  <span class='dataFont' >
                    {{ value.workingHours }}
                  </span>
                </div>
                <!-- 残業時間 -->
                <div class='dataItem overtimeHours'>
                  <span class='dataFont' >
                    {{ value.overtimeHours }}
                  </span>
                </div>
                <!-- 深夜残業時間 -->
                <div class='dataItemEnd midnightOvertimeHours'>
                  <span class='dataFont' >
                    {{ value.midnightOvertimeHours }}
                  </span>
                </div>
                <v-layout>
                  <!-- 編集ボタン -->
                  <v-btn
                    color="primary"
                    @click="clickInfoBtn(value)"
                    v-if="(value.id != 0 && value.workingHours > 0 && value.state == '出勤')"
                  >
                    {{ stringData.info }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="clickInfoBtn(value)"
                    v-else-if="(value.id != 0 && value.workingHours == 0 && value.state == '出勤') && SamePerson()"
                  >
                    {{ stringData.add }}
                  </v-btn>
                  <!-- 削除ボタン -->
                  <v-btn
                    outlined
                    fab
                    small
                    @click="dataDelete(value)"
                    v-if="value.id != 0 &&
                          SamePerson()"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <v-dialog v-model="deleteDlg" max-width="400">
                    <v-card>
                      <v-card-text>
                        <p>削除しますか？</p>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="deleteDlg=false">キャンセル</v-btn>
                        <v-btn @click="dataDelete(value)">OK</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </v-layout>

              <!-- 総合情報 -->
              <v-layout justify-start class='timeCardComprehensiveInformation'>
                <v-layout column>
                  <!-- 出勤日数 -->
                  <v-layout row>
                    <!-- ヘッダ -->
                    <div class='leftHeaderTitleStart leftHedaer'>
                      <span class='headerFont'>
                        {{ stringData.commutingDays }}
                      </span>
                    </div>
                    <!-- データ部 -->
                    <div class='comprehensiveInfoDataItemStart comprehensiveInfo'>
                      <span class='headerFont'>
                        {{this.commutingDays}}
                      </span>
                    </div>
                  </v-layout>

                  <!-- 欠勤日数 -->
                  <v-layout row>
                    <!-- ヘッダ -->
                    <div class='leftHeaderTitle leftHedaer'>
                      <span class='headerFont'>
                        {{ stringData.absenceDays }}
                      </span>
                    </div>
                    <!-- データ部 -->
                    <div class='comprehensiveInfoDataItem comprehensiveInfo'>
                      <span class='headerFont'>
                        {{this.absenceDays}}
                      </span>
                    </div>
                  </v-layout>

                  <!-- 有給日数 -->
                  <v-layout row>
                    <!-- ヘッダ -->
                    <div class='leftHeaderTitle leftHedaer'>
                      <span class='headerFont'>
                        {{ stringData.paidDays }}
                      </span>
                    </div>
                    <!-- データ部 -->
                    <div class='comprehensiveInfoDataItem comprehensiveInfo'>
                      <span class='headerFont'>
                        {{ this.paidDays }}
                      </span>
                    </div>
                  </v-layout>

                  <!-- 代休日数 -->
                  <v-layout row>
                    <!-- ヘッダ -->
                    <div class='leftHeaderTitle leftHedaer'>
                      <span class='headerFont'>
                        {{ stringData.numberOfSubstituteHolidays }}
                      </span>
                    </div>
                    <!-- データ部 -->
                    <div class='comprehensiveInfoDataItem comprehensiveInfo'>
                      <span class='headerFont'>
                        {{ this.numberOfSubstituteHolidays }}
                      </span>
                    </div>
                  </v-layout>
                  <!-- 総勤務時間 -->
                  <v-layout row>
                    <!-- ヘッダ -->
                    <div class='leftHeaderTitle leftHedaer'>
                      <span class='headerFont'>
                        {{ stringData.totalWorkingHours }}
                      </span>
                    </div>
                    <!-- データ部 -->
                    <div class='comprehensiveInfoDataItem comprehensiveInfo'>
                      <span class='headerFont'>
                        {{ this.totalWorkingHours }}
                      </span>
                    </div>
                  </v-layout>
                  <!-- 残業時間 -->
                  <v-layout row>
                    <!-- ヘッダ -->
                    <div class='leftHeaderTitle leftHedaer'>
                      <span class='headerFont'>
                        {{ stringData.totalOvertimeHours }}
                      </span>
                    </div>
                    <!-- データ部 -->
                    <div class='comprehensiveInfoDataItem comprehensiveInfo'>
                      <span class='headerFont'>
                        {{ this.totalOvertimeHours }}
                      </span>
                    </div>
                  </v-layout>
                  <!-- 残業時間 -->
                  <v-layout row>
                    <!-- ヘッダ -->
                    <div class='leftHeaderTitleEnd leftHedaer'>
                      <span class='headerFont'>
                        {{ stringData.totalMidnightOvertimeHours }}
                      </span>
                    </div>
                    <!-- データ部 -->
                    <div class='comprehensiveInfoDataItemEnd comprehensiveInfo'>
                      <span class='headerFont'>
                        {{ this.totalMidnightOvertimeHours }}
                      </span>
                    </div>
                  </v-layout>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Header from '../components/header.vue'
export default {
  name: 'edit',
  // コンポーネント
  components: {
    Header,
  },
  /* 変数各種(関数形式でreturn値が変数として使用できる) */
  data () {
    return {
      /* 文字列データ */
      stringData: {
        title: 'タイムカード一覧',
        headerTitleDate: '日付',
        headerTitleState: '出勤状況',
        headerTitleStartTime: '開始時刻',
        headerTitleEndTime: '終了時刻',
        headerTitleWorkPlace: '作業場所',
        headerTitleOverview: '概要',
        add: '追加',
        info: '  詳細  ',
        commutingDays: '出勤日数',
        absenceDays: '欠勤日数',
        paidDays: '有給日数',
        numberOfSubstituteHolidays: '代休日数',
        totalWorkingHours: '総勤務時間',
        totalOvertimeHours: '残業時間',
        totalMidnightOvertimeHours: '深夜残業時間',
        headerTitleWorkingHours: '勤務時間(分)',
        headerTitleOvertimeHours: '残業時間(分)',
        headerTitleMidnightOvertimeHours: '深夜残業時間(分)'
      },
      /* 仮想データ */
      listData: [],
      commutingDays : 0,
      absenceDays : 0,
      paidDays : 0,
      numberOfSubstituteHolidays : 0,
      totalWorkingHours: 0,
      totalOvertimeHours: 0,
      totalMidnightOvertimeHours: 0,
      selectYear: 0,
      selectMonth: 0,
      yearList: [],
      monthList: [
        1,2,3,4,5,6,7,8,9,10,11,12
      ],
      dayOfWeekStr: [ "日", "月", "火", "水", "木", "金", "土" ],	// 曜日(日本語表記)
      statusTypes: ['出勤', '有給', '代休', '欠勤'], /* 勤怠状況 */
      deleteDlg: false,
      userList: [],
      selectUserId: -1,
      loginUserId: -1,
    }
  },
  computed: {
    ...mapGetters([
      "User/startDate",
      "User/closingDate",
      "User/userName",
      "User/companyId",
      "User/loginUserId",
      "Holiday/data"
    ]),
  },
  mounted : function() {
    var date = new Date();
    var year = date.getFullYear();
    for(var i = year; i >= 2020; i--) {
      this.yearList.push(i);
    }
    this.selectYear = year;
    this.selectMonth = date.getMonth() + 1;
    console.log(date.getDate())
    if (date.getDate() > 20) {
      this.selectMonth += 1
    }
    if (this.selectMonth > 12) {
      this.selectMonth = 1;
    }
    
    console.log("---selectMonth---")
    console.log(this.selectMonth)
    // 社員リスト取得
    this.getEmployeeList()
    this.selectUserId = this["User/loginUserId"]
    console.log("selectUserId : " + this.selectUserId)
    this.loginUserId = this["User/loginUserId"]
    console.log("loginUserId : " + this.loginUserId)
    console.log("社員リスト取得完了")
    // 出勤データ取得
    this.getAttendance()   
  },
  methods: {
    ...mapActions('DailyReport',[
      'selectDate',
    ]),
    ...mapActions('User',[
      'setSelectUserId'
    ]),
    dataFont(isHoliday) {
      if (isHoliday) {
        return "holidayDataFont"
      } else {
        return "dataFont"
      }
    },
    /* 詳細ボタン押下 */
    clickInfoBtn(value) {
      console.log('clickInfoBtn:' + value)
      this["selectDate"](value.date)
      if (value.id == '' || (Number(value.workingHours) == 0)) {
        this.$router.push({
          name : 'TimeCardAdd',
          params: { mode: 'add' }
        })
      } else {
        this.$router.push({
          name : 'TimeCardInfo',
        })
      }
    },
    // 追加ボタン押下
    clickAddBtn(value) {
      console.log('clickAddBtn:' + value)
      this["selectDate"](value.date)
        this.$router.push({
        name : 'TimeCardAdd',
        params: { mode: 'add' }
      })
    },
    // CSV取得
    async clickCSV() {
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/TimeCardCSV';
      let params = {};
      params.tokenString = localStorage.getItem("Token");
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data)
        var content = "\ufeff" + res.data;
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";
        const blob = new Blob([content], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = new Date().toISOString() + ".csv";
        a.click();
        window.URL.revokeObjectURL(url); // release the used object.
        a.parentNode.removeChild(a); // delete the temporary "a" element
      }).catch(err => {
       console.log(err)
      })  
    },
    // 月変更
    async changeMonth() {
      await this.getAttendance()
    },

    // 出勤区分変更
    async changeState(value) {
      console.log('changeState')
      console.log(value)
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/InsertAttendance';
      let params = {};
      params.tokenString = localStorage.getItem("Token")
      params.date = value.date;
      var i;
      if (value.state == this.statusTypes[0]) {
        i = 1;
      } else if (value.state == this.statusTypes[1]) {
        i = 2;
      } else if (value.state == this.statusTypes[2]) {
        i = 3;
      } else if (value.state == this.statusTypes[3]) {
        i = 4;
      }
      params.attendanceDivId = String(i);
      console.log(params)
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data.msg)
        // alert(res.data.msg)
        this.getAttendance()
      }).catch(err => {
       console.log(err)
      })
      this.stateCmpFlag = false  
    },

    // 出勤データ取得
    async getAttendance() {
      console.log("出勤データ取得")
      console.log(this["User/userName"])
      // 表示データの初期化
      this.listData = []
      this.commutingDays = 0
      this.absenceDays = 0
      this.paidDays = 0
      this.numberOfSubstituteHolidays= 0
      this.totalWorkingHours = 0
      this.totalOvertimeHours = 0
      this.totalMidnightOvertimeHours = 0

      const url = 'https://testerp2.ecosysnetwork.co.jp:444/GetAttendance';
      let params = {};
      console.log(this["Holiday/data"])
      let holidayList = this["Holiday/data"]
      // トークン
      params.tokenString = localStorage.getItem("Token");
      params.userId = String(this.selectUserId)
      console.log(this.selectUserId)
      // 開始日
      var startDate = new Date();
      // 年を指定
      startDate.setFullYear(this.selectYear);
      // 現在月の1月前の開始日を対象とする (date型の月は0が1月になるため、現在月から2引いた値にする)
      startDate.setMonth(this.selectMonth - 2, 1);
      startDate.setDate(this["User/startDate"]);
      // 終了日
      var endDate = new Date();
      // 現在月の締日を対象にする
      endDate.setFullYear(this.selectYear);
      endDate.setMonth(this.selectMonth - 1, 1);
      endDate.setDate(this["User/closingDate"]);
      console.log("selectMonth")
      console.log(this.selectMonth)
      console.log("startDate")
      console.log(startDate)
      console.log("endDate")
      console.log(endDate)

      params.startTime = startDate.toISOString().substr(0, 10);
      params.endTime = endDate.toISOString().substr(0, 10);

      var diffMilliSec = endDate - startDate;
      var diffDays = parseInt(diffMilliSec / 1000 / 60 / 60 / 24);
      console.log(diffDays)
      // 開始日から締め日までのデータリスト生成
      for(let i = 0; i <= diffDays; i++) {
        var tmpDate = new Date(startDate);
        tmpDate.setDate(tmpDate.getDate() + i);
        var tmpDateString = (tmpDate.getMonth() + 1) + "/" + tmpDate.getDate() + " (" + this.dayOfWeekStr[tmpDate.getDay()] + ")";
        var holiday = (tmpDate.getDay() == 0 ||tmpDate.getDay() == 6)
        var idx = holidayList.findIndex(value => 
          (value.HolidayDate.getFullYear() == tmpDate.getFullYear()) &&
          (value.HolidayDate.getMonth() == tmpDate.getMonth()) &&
          (value.HolidayDate.getDate() == tmpDate.getDate())
        )
        if (idx != -1) {
          console.log(holidayList[idx])
          holiday = true
        }

        this.listData.push({id: "", 
                              date: this.formatDate(tmpDate),
                              dateString: tmpDateString,
                              state: "", 
                              startTime: "",
                              endTime: "", 
                              workPlece: "", 
                              overView: "", 
                              workingHours: "0",
                              overtimeHours: "0",
                              midnightOvertimeHours: "0",
                              IsHoliday: holiday,
                            })
      }
      console.log(this.listData);
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      ).then(res => {
        console.log(res)
        res.data[0].list.forEach(element => 
        {
          console.log(element)
          var date = new Date(element.Date)
          console.log(date);
          var dateString = (date.getMonth() + 1) + "/" + date.getDate() + " (" + this.dayOfWeekStr[date.getDay()] + ")"
          // 日付でリスト内を検索する
          var idx = this.listData.findIndex(value => value.id == "" && value.date== this.formatDate(date));
          if (idx != -1) {
            console.log(idx)
            // 要素を更新
            this.listData[idx].id = element.AttendanceId
            this.listData[idx].date = String(element.Date).substr(0, 10)
            this.listData[idx].dateString = dateString
            this.listData[idx].state = element.State
            if (element.StartTime != element.EndTime) {
              this.listData[idx].startTime = String(element.StartTime).substr(11, 5)
              this.listData[idx].endTime = String(element.EndTime).substr(11, 5)
              this.listData[idx].workPlece = element.WorkPlace
              this.listData[idx].overView = element.Overview
            }
            this.listData[idx].workingHours = element.WorkingHours
            this.listData[idx].overtimeHours = element.OvertimeHours
            this.listData[idx].midnightOvertimeHours = element.MidnightOvertimeHours
          }
            if (element.State == '出勤') {
              this.commutingDays ++
            } else if (element.State == '欠勤') {
              this.absenceDays++
            } else if (element.State == '有給') {
              this.paidDays ++
            } else if (element.State == '代休') {
              this.numberOfSubstituteHolidays++
            }
          }
        );
        
        this.totalWorkingHours = res.data[0].workingHour
        this.totalOvertimeHours = res.data[0].overTimeHour
        this.totalMidnightOvertimeHours = res.data[0].midnightOvertimeHours

        let workingDays = this.listData.filter(function(x){return x.IsHoliday===false}).length;
        console.log(workingDays)
        }).catch(err => {
        console.log(err)
      })
    },
    // データ削除
    async dataDelete(value) {
      this.deleteDlg = false
      console.log(value.id)
      console.log('changeState')
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/DeleteAttendance';
      let params = {};
      params.tokenString = localStorage.getItem("Token")
      params.attendanceId = String(value.id)
      console.log(params)
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res)
        this.getAttendance()
      }).catch(err => {
       console.log(err)
      })
    },
    // 当日ボタンクリック
    async todayData() {
      console.log('clickBackBtn:' )
      
      // 当日データ画面に遷移
      this.$router.push({
          name : 'TimeCardAddToday',
        })
    },

    // 社員リスト取得
    async getEmployeeList() {
      console.log("getEmployeeList")
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/GetEmployee';
      let params = {};
      params.tokenString = localStorage.getItem("Token")
      params.companyId = String(this['User/companyId'])
      console.log(params)
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res)
        res.data.forEach(element => {
          console.log(element.UserId)
          console.log(element.UserName)
          this.userList.push({
            id: element.UserId,
            name: element.UserName
          })
        });
      }).catch(err => {
       console.log(err)
      })
    },

    async changeUser() {
      console.log(this.selectUserId)
      this['setSelectUserId'](this.selectUserId)
      this.getAttendance()
    },

    checkState() {
      console.log("checkState")
      var date = this.formatDate(new Date())
      var todayDataIdx = this.listData.findIndex(elm => elm.date == date)
      console.log("todayDataIdx = " + todayDataIdx)
      
      if (todayDataIdx == -1) {
        return false;
      } else if (this.listData[todayDataIdx].state == "" || 
      this.listData[todayDataIdx].state == "出勤") {
        console.log("state = " + this.listData[todayDataIdx].state)
        return true;
      } else {
        console.log("state = " + this.listData[todayDataIdx].state)
        return false;
      }
    }
  }
}
</script>

<style>
/* ページタイトルスタイル */
.timeCardTitle {
  margin-left: 2%;
}

/* カードスタイル */
.timeCard {
  padding: 2%;
}

/* 新規追加ボタンスタイル */
.addBtn {
  margin-bottom: 2%;
}

/* 総合情報スタイル */
.timeCardComprehensiveInformation {
  margin-top: 3%;
}

/* 日付列スタイル */
.date {
  width: 12%;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 出勤状況列スタイル */
.state {
  width: 9%;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 開始時刻列スタイル */
.stratTime {
  width: 9%;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 終了時刻列スタイル */
.endTime {
  width: 9%;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 勤務時間列スタイル */
.workingHours {
  width: 9%;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 残業時間列スタイル */
.overtimeHours {
  width: 9%;
  justify-content: center;
  align-items: center;
  display: flex;
}
/* 深夜残業時間列スタイル */
.midnightOvertimeHours {
  width: 9%;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 作業場所列スタイル */
.workPlace {
  width: 16%;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 概要列スタイル */
.overview {
  width: 45%;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 左列ヘッダスタイル */
.leftHedaer {
  width: 12%;
}

/* 総合情報データ部 */
.comprehensiveInfo {
  width: 12%;
}

/* ヘッダフォントスタイル */
.headerFont {
  font-family:'メイリオ', 'Meiryo', sans-serif;
  color: #696969;
}

/* データ部フォントスタイル */
.dataFont {
  font-family:'メイリオ', 'Meiryo', sans-serif;
  color: #696969;
}
/* データ部フォントスタイル */
.holidayDataFont {
  font-family:'メイリオ', 'Meiryo', sans-serif;
  color: #fc0000;
}
/* ヘッダ先頭列スタイル */
.headerTitleStart {
  border-top-left-radius: 0.5em;
  border-top: thin solid #c0c0c0;
  border-bottom: thin solid #c0c0c0;
  border-left: thin solid #c0c0c0;
  background-color: #dcdcdc;
  padding-top: 1%;
  padding-bottom: 1%;
}

/* ヘッダスタイル */
.headerTitle {
  border-top: thin solid #c0c0c0;
  border-bottom: thin solid #c0c0c0;
  border-left: thin solid #c0c0c0;
  background-color: #dcdcdc;
  padding-top: 1%;
  padding-bottom: 1%;
}

/* ヘッダ終端列スタイル */
.headerTitleEnd {
  border-top-right-radius: 0.5em;
  border-top: thin solid #c0c0c0;
  border-bottom: thin solid #c0c0c0;
  border-left: thin solid #c0c0c0;
  border-right: thin solid #c0c0c0;
  background-color: #dcdcdc;
  padding-top: 1%;
  padding-bottom: 1%;
}

/* データ部スタイル */
.dataItem {
  border-bottom: thin solid #c0c0c0;
  border-left: thin solid #c0c0c0;
  /* padding-top: 1%; */
  padding-left: 1%;
  padding-right: 1%;
  /* padding-bottom: 1%; */
}

/* データ部終端列スタイル */
.dataItemEnd {
  border-bottom: thin solid #c0c0c0;
  border-left: thin solid #c0c0c0;
  border-right: thin solid #c0c0c0;
  /* padding-top: 1%; */
  padding-left: 1%;
  padding-right: 1%;
  /* padding-bottom: 1%; */
}

/* 左ヘッダ先頭行スタイル */
.leftHeaderTitleStart {
  border-top-left-radius: 0.5em;
  border-top: thin solid #c0c0c0;
  border-left: thin solid #c0c0c0;
  border-right: thin solid #c0c0c0;
  background-color: #dcdcdc;
  padding-top: 1%;
  padding-bottom: 1%;
}

/* 左ヘッダスタイル */
.leftHeaderTitle {
  border-top: thin solid #c0c0c0;
  border-left: thin solid #c0c0c0;
  border-right: thin solid #c0c0c0;
  background-color: #dcdcdc;
  padding-top: 1%;
  padding-bottom: 1%;
}

/* 左ヘッダ終端行スタイル */
.leftHeaderTitleEnd {
  border-bottom-left-radius: 0.5em;
  border-top: thin solid #c0c0c0;
  border-left: thin solid #c0c0c0;
  border-right: thin solid #c0c0c0;
  border-bottom: thin solid #c0c0c0;
  background-color: #dcdcdc;
  padding-top: 1%;
  padding-bottom: 1%;
}

/* 先頭行データ部スタイル */
.comprehensiveInfoDataItemStart {
  border-top-right-radius: 0.5em;
  border-top: thin solid #c0c0c0;
  border-right: thin solid #c0c0c0;
  padding-top: 1%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
}

/* データ部スタイル */
.comprehensiveInfoDataItem {
  border-top: thin solid #c0c0c0;
  border-right: thin solid #c0c0c0;
  padding-top: 1%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
}

/* 先頭行データ部スタイル */
.comprehensiveInfoDataItemEnd {
  border-bottom-right-radius: 0.5em;
  border-top: thin solid #c0c0c0;
  border-right: thin solid #c0c0c0;
  border-bottom: thin solid #c0c0c0;
  padding-top: 1%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
}
</style>