import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import DailyReport from './modules/dailyReport'
import User from './modules/user'
import Holiday from './modules/holiday'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    DailyReport,
    User,
    Holiday,
  },
  plugins : [createPersistedState(
    { // ストレージのキーを指定。デフォルトではvuex
      key: 'testerp',
      // 管理対象のステートを指定。pathsを書かないときは'modules'に書いたモジュールに含まれるステート全て
      paths:['User'],
      // ストレージのsh類を指定する。デフォルトではローカルストレージ
      storage: window.sessionStorage
    }
  )]
})