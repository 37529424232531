import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/Login.vue'
import TimeCard from '@/TimeCard/edit'
import TimeCardInfo from '@/TimeCardInfo/edit'
import TimeCardAdd from '@/TimeCardAdd/edit'
import TimecardAddToday from '@/TimeCardAdd/today'
// store
import Store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/TimeCard",
    name: "TimeCard",
    component: TimeCard
  },
  {
    path: "/TimeCardInfo",
    name: "TimeCardInfo",
    component: TimeCardInfo
  },
  {
    path: "/TimeCard/today",
    name: "TimeCardAddToday",
    component: TimecardAddToday,
    props: true
  },
  {
    path: "/TimeCard/:mode",
    name: "TimeCardAdd",
    component: TimeCardAdd,
    props: true
  },

] 

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !Store.state.token) {
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});
export default router