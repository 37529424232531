<template>
  <header class="mainHeader">
    <v-layout>
      <v-flex xs12>
        <v-row>
          <v-col cols="2">
            業務管理システム
          </v-col>
          <v-col cols="10">
            <span>
              ユーザー名 : {{ userName }}
          </span>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </header>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  /* 変数各種(関数形式でreturn値が変数として使用できる) */
  data () {
    return {
        userName: "",
    }
  },
  computed: {
   ...mapGetters([
      "User/startDate",
      "User/closingDate",
      "User/userName",
      "Holiday/data"
    ]),
  },
  mounted : function() {
    this.getUserName()
  },
  methods : {
    getUserName() {
        console.log((this["User/userName"]))
        this.userName = String(this["User/userName"]);
    }
  }
}

</script>
<style>
  .mainHeader {
    background-color:blue;
    color: white;
    width: 100%;
    height: 48px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
</style>