import axios from 'axios'
import { mapGetters } from 'vuex'

// グローバルミックスイン
export default {
  data() {
    return {
      URL: "https://testerp2.ecosysnetwork.co.jp:444/",    // サーバーサイドのパスを定義
    }
  },
  computed: {
    ...mapGetters('User',[
      "loginUserId",
      "selectUserId",
    ]),
  },
  methods: {
    // トークンチェック
    async _tokenChk() {
      // 現在のログイントークンをパース
      var decToken = this.decodeToken(localStorage.getItem("Token"));
      // 現在日時取得
      var now = new Date().getTime() / 1000;
      // トークンの有効期限と現在日時を比較
      if (decToken.exp < now) {
        console.log("有効期限切れ")
        // ログイントークンの更新
        await this.updateToken(decToken)
      } else {
        console.log("有効期限内")
      }
    },
    // ログイントークン更新
    async updateToken(decToken) {
      // トークン再発行
      const refreshUrl = 'https://testerp2.ecosysnetwork.co.jp:444/refresh';
      let refreshParam = {};
      refreshParam.userId = decToken.userId;
      refreshParam.refreshTokenId = decToken.refreshTokenId;
      refreshParam.refreshToken = localStorage.getItem("RefreshToken");
      console.log(this.decodeToken(localStorage.getItem("RefreshToken")));

      await axios.post(
        refreshUrl, 
        refreshParam,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log("トークン再発行完了")
        console.log(res.data)
        if (res.data[0] != "") {
          localStorage.setItem("Token", res.data.token);
          localStorage.setItem("RefreshToken", res.data.refreshToken);
        }
      }).catch(err => {
          console.log(err)
      })  
    },
    // 日付取得(yyyy-mm-dd形式)
    formatDate(dt) {
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    }, 

    // 選択ユーザーとログインユーザーが同一であるか
    SamePerson() {
      console.log(this["loginUserId"] ==this["selectUserId"])
      return this["loginUserId"] ==this["selectUserId"]
    }
  },
}