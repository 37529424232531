<template>
  <v-app>
    <Header></Header>
    <v-layout row justify-center align-center>
      <v-flex xs7>
        <v-layout justify-start>
          <!-- 戻るボタン -->
          <!-- <router-link :to="{name : 'TimeCard'}" tag="button"> -->
            <v-btn color='primary' class="addBtn" @click="clickBackBtn">
              <v-icon color="white">mdi-keyboard-return</v-icon>
                {{ stringData.back }}
            </v-btn>
          <!-- </router-link> -->
          <!-- 追加 -->
          <v-btn color="primary" class="addBtn" @click="clickAddBtn" v-if="SamePerson()">
            <v-icon color="white">mdi-plus</v-icon>
              {{ stringData.add }}
          </v-btn>
        </v-layout>

        <v-card>
          <v-card-title>
            <h1 class="title">
              {{ stringData.title }}
            </h1>
          </v-card-title>
          
          <v-card-text>
            <v-layout justify-start>
              <div class="infoMessageFont">{{ stringData.infoState }}</div>
            </v-layout>
            <v-layout column v-for="(value, index) in items" :key="index">
              <div class="grayBorder">
                <v-layout row>
                  <v-flex xs10>
                    <v-layout column>
                      <!-- 開始時刻 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.startTime }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont">{{ value.startTime }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <!-- 終了時刻 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.endTime }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont">{{ value.endTime }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <!-- 登録時刻 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.registrationTime }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont">{{ value.registrationTime }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <!-- 作業場所 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.workPlace }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont">{{ value.workPlace }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <!-- 概要入力 -->
                      <v-col cols="12" sm="11" md="8" class="itemStyle">
                        <v-layout row justify-start>
                          <v-flex xs3>
                            <div class="headerMessageFont">{{ stringData.overview }}:</div>
                          </v-flex>
                          <v-flex xs7>
                            <div class="infoMessageFont">{{ value.overview }}</div>
                          </v-flex>
                        </v-layout>
                      </v-col>
                    </v-layout>
                  </v-flex>

                  <v-flex xs1>
                    <v-layout column>
                      <v-col cols="12" sm="11" md="8">
                        <!-- 編集ボタン -->
                        <v-btn
                          color="primary"
                          elevation="2"
                          fab
                          dark
                          small
                          @click="dataEdit(value)"
                          v-if="SamePerson()"
                        >
                          <v-icon dark>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </v-col>

                      <!-- <v-col cols="12" sm="11" md="8"> -->
                        <!-- 削除ボタン -->
                        <!-- <v-btn
                          outlined
                          fab
                          small
                          @click="dataDelete(value.id)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col> -->
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Header from '../components/header.vue'
export default {
  name: 'edit',
  // コンポーネント
  components: {
    Header,
  },
  /* 変数各種(関数形式でreturn値が変数として使用できる) */
  data () {
    return {
      /* 文字列データ */
      stringData: {
        title: 'タイムカード詳細',
        back: '戻る',
        startTime: '開始時刻',
        endTime: '終了時刻',
        workPlace: '作業場所',
        overview: '概要',
        infoState: 'タイムカードの詳細です。',
        registrationTime: '登録時刻',
        add: '新規追加',
      },

      /* 変数 */
      userId: -1,
      /* データリスト格納用 */
      items: [
        // {id: 1, startTime: '9:00', endTime: '12:00', workPlace: '事務所', overview: '業務管理システム開発'},
        // {id: 2, startTime: '13:00', endTime: '17:00', workPlace: '事務所', overview: '業務管理システム開発'},
        // {id: 3, startTime: '17:00', endTime: '18:00', workPlace: '事務所', overview: '掃除'}
      ]
    }
  },
  computed: {
    ...mapGetters('DailyReport',[
      "trgDate"
    ]),
    ...mapGetters('User',[
      "selectUserId"
    ]),
  },
  mounted : function() {
    console.log(this.getDailyReport);
    this.userId = this["selectUserId"]
    this.getDailyData()
  },
  methods : {
    ...mapActions('DailyReport',[
      'selectData',
    ]),
    // 新規追加ボタンクリック
    async clickAddBtn() {
      console.log('clickAddBtn:' ) 
            
      this.$router.push({
        name : 'TimeCardAdd',
        params: { mode: 'add' }
      })
    },
    // 戻るボタンクリック
    async clickBackBtn() {
      console.log('clickBackBtn:' )
      // 一つ前の画面に遷移させる
      this.$router.back()
    },
    /* データ削除 */
    async dataDelete(value) {
      console.log(value)
      console.log(typeof(value))
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/DeleteTimeCard'
      let params = {};
      // トークン
      params.tokenString = localStorage.getItem("Token");
      // 対象ID
      params.dailyReportId = String(value);
      // 削除実行
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data)        
      }).catch(err => {
       console.log(err)
      })
      // 削除後、再度日報データ取得(表示データ更新のため)
      this.getDailyData();
    },

    /* データ編集 */
    async dataEdit(value) {
      console.log('dataEdit')
      console.log(value)
      this["selectData"](value)
      this.$router.push({
        name : 'TimeCardAdd',
        params: { mode: 'edit' }
      })
    },
    // 日報データ取得
    async getDailyData() {
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/TimeCard'
      let params = {};
      // トークン
      params.tokenString = localStorage.getItem("Token");
      params.userId = String(this.userId)
      // 対象日
      params.trgDate = String(this["trgDate"])
      await axios.post(
        url, 
        params,
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data)
        res.data.forEach(element => {
          console.log(element.EndDate)
          console.log(element.EndDate.substr(11, 5))
          this.items.push({
            id: element.ReportId, 
            startTime: element.StartDate.substr(11, 5), 
            endTime: element.EndDate.substr(11, 5), 
            registrationTime: element.RegistrationTime.substr(0, 10) + " " + element.RegistrationTime.substr(11, 5), 
            workPlace: element.WorkPlace, 
            overview: element.Overview
          })  
        });
        
      }).catch(err => {
       console.log(err)
      }) 
    }
  }
}
</script>

<style>
/* 枠線スタイル */
.grayBorder {
  border-radius: 0.5em;
  border: thin solid #c0c0c0;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 3%;
}

/* 項目スタイル */
.itemStyle {
  margin-left: 5%;
}

/* ヘッダフォントスタイル */
.headerMessageFont {
  margin-top: 9%;
  margin-bottom: 1%;
  font-size: 1.2em;
  font-weight: bold;
  font-family:'メイリオ', 'Meiryo', sans-serif;
  color: #696969;
}

/* 説明フォントスタイル */
.infoMessageFont {
  font-family:'メイリオ', 'Meiryo', sans-serif;
  color: #696969;
}

/* 勤怠状況スタイル */
.status {
  margin-bottom: 4%;
}
.textPlacement {
    justify-content: center;
    align-items: center;
}

/* 新規追加ボタンスタイル */
.addBtn {
  margin-bottom: 2%;
}
</style>