<template>
  <v-app>
    <v-layout row justify-center align-center>
      <v-flex xs4>
        <v-card>
          <v-card-title>
            <h1 class="title">ログイン</h1>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field v-model="userId" label="ユーザ名" />
              <v-text-field v-model="password" type="password" label="パスワード" />
              <v-card-actions>
                <v-layout row justify-center>
                  <!-- <router-link to="/TimeCard" tag="button"> -->
                    <v-btn color="primary" @click="login(false)">
                      ログイン
                    </v-btn>
                  <!-- </router-link> -->
                </v-layout>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <router-view/>
  </v-app>
</template>
<script>

import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'app',

  components: {
  },

  data: () => ({
    message: '',
    ret: ''
  }),
  mounted : function(){
    this.login(true)
  },
  methods : {
    ...mapActions('Holiday',[
      'add',
    ]),

    ...mapActions('User',[
      'setSummaryNumberOfCharacters',
      'setUserName',
      'setCompanyId',
      'setLoginUserId',
      'setSelectUserId'
    ]),
    async login(autoLogin) {
      const url = 'https://testerp2.ecosysnetwork.co.jp:444/login';
      const url2 = 'https://testerp2.ecosysnetwork.co.jp:444/GetHoliday';
      let params = {};
      let success = false; 
      params.userName = this.userId;
      params.password = this.password;
      // トークン期限確認・再発行
      if (localStorage.getItem("Token") != null && autoLogin) {
        // トークンの期限確認及びリフレッシュトークンを用いたトークン再発行
        await this._tokenChk();
        console.log("トークンチェック終了")
        // パラメータにトークン追加
        params.tokenString = localStorage.getItem("Token");
        console.log(params)
      }
      // ログイン
      await axios.post(
        url, 
        params, 
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(res => {
        console.log(res.data)
        if (res.data[0] != "") {
          if (!autoLogin) {
            localStorage.setItem("Token", res.data[0].Token);
            localStorage.setItem("RefreshToken", res.data[0].RefreshToken);
            
          }
          if (res.data[0].UserId != "") {
            success = true;
            this["setSummaryNumberOfCharacters"](res.data[0].Length)
            this["setLoginUserId"](res.data[0].UserId)
            this["setSelectUserId"](res.data[0].UserId)
            this["setUserName"](res.data[0].UserName)
            this["setCompanyId"](res.data[0].CompanyID)
          }
        }
      }).catch(err => {
        console.log(err)
      }) 

      if (success){
        console.log("success")
        // 祝日リスト取得
        await axios.post(
          url2, 
          params, 
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then(res => {
          console.log(res.data)
          if (res.data != "") {
            let list = []
            res.data.forEach(element => {
              let date = new Date(element.HolidayDate)
              list.push(
                {
                  HolidayDate : date,
                  HolidayDateTime : date.getTime(),
                  HolidayName : element.HolidayName
                }
              )
            });
            this["add"](list)
          }
        }).catch(err => {
          console.log(err)
        }) 
        // 画面遷移
        this.$router.push({
          name: 'TimeCard',
        })
      }
    },
    
    // トークンのデコード
    decodeToken(token) {                                        
      const base64Url = token.split('.')[1];                             
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');    
      return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
    },
  }
};
</script>
